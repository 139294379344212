<template>
  <v-container fluid>
    <alert-table
      :route="'history'"
      :headers="headers"
      :expanded-headers="expanded"
      :data="data"
      :loading="loading"
      :table-page="page"
      :table-page-total-count="pageTotalCount"
      :table-size="size"
      @change="loadData($event.page, $event.size, $event.sortDesc, $event.by)"
      @onSearch="onSearch"
      @onMaskClick="onMaskClick($event)"
    />
  </v-container>
</template>

<script>
import alertTable from "@/views/monitor/auditor/integrity/components/tableHistory";
import { Errors } from "@/utils/error";
import { getIntegrityHistoryAlerts } from "@/api/monitor/auditor";

export default {
  components: {
    alertTable,
  },
  data() {
    return {
      headers: [],
      expanded: [],
      data: [],
      loading: true,

      page: 1,
      pageTotalCount: 1,
      size: 25,
      sizeTotalCount: 0,
    };
  },
  computed: {
    changeInAddForm: {
      get() {
        return this.$store.state.settings.changeInAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "changeInAddForm",
          value: val,
        });
      },
    },
    monitorAuditorIntegrityHistoryTable: {
      get() {
        return this.$store.state.tables.monitorAuditorIntegrityHistoryTable;
      },
      set(val) {
        this.$store.dispatch("store/tables/changeSetting", {
          key: "monitorAuditorIntegrityHistoryTable",
          value: val,
        });
      },
    },
  },
  watch: {
    changeInAddForm: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue === "auditorAlert") {
          this.loadData(this.page, this.size);
        }
        this.changeInAddForm = "";
      },
    },
    monitorAuditorIntegrityHistoryTable: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          const self = this;
          this.headers = [];
          newValue.headers.forEach(function (header) {
            if (header.visible) {
              self.headers.push(header);
            }
          });
          this.expanded = [];
          newValue.expanded.forEach(function (item) {
            if (item.visible) {
              self.expanded.push(item);
            }
          });
        }
      },
    },
  },
  created() {
    this.loadData(this.page, this.size);
  },
  methods: {
    loadData(page, size, sortDesc, by) {
      this.loading = true;
      let newPage = page;
      if (size !== this.size) {
        newPage = 1;
      }
      getIntegrityHistoryAlerts(newPage, size, sortDesc, by, this.search)
        .then((response) => {
          this.loading = false;
          this.data = response.data;
          this.data.forEach(function (value) {
            value.deleteMenu = false;
            value.isMask = true;
          });
          this.pageTotalCount = response.last_page;
          this.page = response.current_page;
          this.size = parseInt(response.per_page);
          this.sizeTotalCount = response.total;
        })
        .catch((e) => {
          Errors.show(e);
          this.loading = false;
        });
    },
    onSearch(event) {
      this.search = event;
      this.page = 1;
      this.loadData(this.page, this.size);
    },
    onMaskClick(event) {
      const item = event.data;
      const index = event.index;
      item.isMask = !item.isMask;
      this.$set(this.data, index, item);
    },
  },
};
</script>
