<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <SearchBar
        search-label="Search Alert"
        @onSearch="onSearch"
        :is-loading="loading"
        :export-loading="exportLoading"
        download-csv-button
        @exportData="exportData"
      />
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="data"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="customSort"
        @update:sort-desc="customSort"
      >
        <template v-slot:item.auditors.name="{ item }">
          <span>
            {{ item.auditor_name }}
          </span>
        </template>
        <template v-slot:item.severity="{ item }">
          <v-icon :class="severityClass(item.severity)"> mdi-view-grid </v-icon>
          {{ severityNames[item.severity - 1] }}
        </template>
        <template v-slot:item.firewalls.name="{ item }">
          <v-chip
            v-if="item.firewall_name"
            x-small
            color="neutral-4"
            class="mr-1 text-white"
          >
            {{ item.firewall_name }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:item.xpath="{ item }">
          {{ item.xpath }}
        </template>
        <template v-slot:item.mask="{ item }">
          <v-btn
            :disabled="!checkPermission(['view advancedmode'])"
            small
            outlined
            @click="onMaskClick(item, data.indexOf(item))"
            class="btn-mask"
          >
            <v-icon class="mask" v-if="item.isMask">mdi-eye</v-icon
            ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.diff="{ item }">
          <div
            v-if="
              item.expectedWithConditions &&
              item.resultWithConditions &&
              item.resultWithConditions !== item.expectedWithConditions
            "
          >
            <div class="d-flex">
              <span class="w-50">Database config</span>
              <span>Firewall config</span>
            </div>
            <code-diff
              class="synapsa-table-inside"
              id="synapsa-table-fix"
              :old-string="
                formattedXml(
                  item.expectedWithConditions,
                  item.maskedTags,
                  item.mask,
                  item.isMask
                )
              "
              :new-string="
                formattedXml(
                  item.resultWithConditions,
                  item.maskedTags,
                  item.mask,
                  item.isMask
                )
              "
              output-format="side-by-side"
            />
          </div>
        </template>
        <template v-slot:item.reasons="{ item }">
          <div class="reasons-results--headers">
            <div class="reasons-result--headers">
              <span
                v-if="
                  item.expected && item.result && item.expected !== item.result
                "
                ><b>Compared configurations are not the same!</b><br
              /></span>
              <span v-if="item.customConditionsResults.length">
                <b
                  v-if="
                    item.customConditionsResults.filter(
                      (condition) => condition.failed
                    ).length
                  "
                  >Some of the custom conditions are not matched:</b
                >
                <b v-else>Custom condition list:</b>
                <br
              /></span>
              <v-list
                dense
                :elevation="1"
                class="condition-list"
                v-if="item.customConditionsResults.length"
              >
                <v-list-item
                  v-for="condition in item.customConditionsResults"
                  :key="condition.fullCondition"
                  rounded
                >
                  <span>
                    <v-icon
                      :color="condition.failed ? 'bad-2' : 'good-2'"
                      small
                    >
                      fas fa-circle
                    </v-icon>
                    {{ formattedConditions(condition.fullCondition) }}
                  </span>
                </v-list-item>
              </v-list>
            </div>
          </div>
        </template>

        <template
          v-if="expandedHeaders.length"
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td v-if="expandItem.value === 'xpath'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'xpath'">
                      {{ item.xpath }}
                    </td>
                    <td
                      v-if="expandItem.value === 'firewalls.name'"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'firewalls.name'">
                      {{ item.firewall_name }}
                    </td>
                    <td v-if="expandItem.value === 'first_seen'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'first_seen'">
                      {{ item.first_seen }}
                    </td>
                    <td v-if="expandItem.value === 'fixed_date'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'fixed_date'">
                      {{ item.fixed_date }}
                    </td>
                    <td v-if="expandItem.value === 'severity'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-icon :class="severityClass(item.severity)">
                        mdi-view-grid
                      </v-icon>
                      {{ severityNames[item.severity - 1] }}
                    </td>
                    <td
                      v-if="expandItem.value === 'auditors.name'"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'auditors.name'">
                      {{ item.auditor_name }}
                    </td>

                    <td v-if="expandItem.value === 'hash'" class="pl-3">
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'hash'">
                      {{ item.hash }}
                    </td>


                    <td
                      v-if="expandItem.value === 'status'"
                      width="150"
                      class="pl-3"
                    >
                      Status
                    </td>
                    <td v-if="expandItem.value === 'status'">
                      {{ item.status }}
                    </td>

                    <td
                      v-if="expandItem.value === 'reasons'"
                      width="150"
                      class="pl-3"
                    >
                      Reasons for alert
                    </td>
                    <td v-if="expandItem.value === 'reasons'">
                      <span v-if="item.expected !== item.result"
                        ><b>Compared configurations are not the same!</b><br
                      /></span>
                      <span v-if="item.customConditionsResults.length"
                        ><b>Custom conditions are not met:</b><br
                      /></span>
                      <v-list
                        dense
                        :elevation="1"
                        class="condition-list"
                        v-if="item.customConditionsResults.length"
                      >
                        <v-list-item
                          v-for="condition in item.customConditionsResults"
                          :key="condition.fullCondition"
                          rounded
                        >
                          <span>
                            <v-icon
                              :color="condition.failed ? 'bad-2' : 'good-2'"
                              small
                            >
                              fas fa-circle
                            </v-icon>
                            {{ formattedConditions(condition.fullCondition) }}
                          </span>
                        </v-list-item>
                      </v-list>
                    </td>

                    <td
                      v-if="expandItem.value === 'mask'"
                      width="150"
                      class="pl-3"
                    >
                      Mask
                    </td>
                    <td v-if="expandItem.value === 'mask'">
                      <v-btn
                        :disabled="!checkPermission(['view advancedmode'])"
                        small
                        outlined
                        @click="onMaskClick(item, data.indexOf(item))"
                        class="btn-mask"
                      >
                        <v-icon class="mask" v-if="item.isMask">mdi-eye</v-icon
                        ><v-icon class="mask" v-else>mdi-eye-off</v-icon>
                      </v-btn>
                    </td>

                    <td
                      v-if="expandItem.value === 'expected'"
                      width="150"
                      class="pl-3 pt-2 pb-2"
                    >
                      Expected
                      <tr id="tr-fix">
                        <div class="btn-big">
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabExpected !== 'format'"
                              :color="
                                contentTabExpected === 'format' ? 'primary' : ''
                              "
                              :outlined="contentTabExpected === 'format'"
                              rounded
                              @click="contentTabExpected = 'format'"
                            >
                              <span>Format</span>
                            </v-btn>
                          </td>
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabExpected !== 'raw'"
                              :color="
                                contentTabExpected === 'raw' ? 'primary' : ''
                              "
                              :outlined="contentTabExpected === 'raw'"
                              rounded
                              @click="contentTabExpected = 'raw'"
                            >
                              <span>Raw</span>
                            </v-btn>
                          </td>
                        </div>
                      </tr>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'expected' &&
                        contentTabExpected === 'format'
                      "
                    >
                    <span
                      v-if="item.emptyExpected"
                      class="expansion-panel-header__header"
                    >WITHOUT CONTENT</span
                    >
                      <v-expansion-panels
                      v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Formated data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <xml-viewer
                              ref="viewer"
                              :xml="
                                formattedXml(
                                  item.expected,
                                  item.maskedTags,
                                  item.mask,
                                  item.isMask
                                )
                              "
                              :class="$vuetify.theme.dark ? 'dark' : 'light'"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'expected' &&
                        contentTabExpected === 'raw'
                      "
                    >
                      <span
                        v-if="item.emptyResult"
                        class="expansion-panel-header__header"
                      >WITHOUT CONTENT</span
                      >
                      <v-expansion-panels
                      v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Raw data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            {{
                              formattedXml(
                                item.result,
                                item.maskedTags,
                                item.mask,
                                item.isMask
                              )
                            }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>

                    <td
                      v-if="expandItem.value === 'result'"
                      width="150"
                      class="pl-3 pt-2 pb-2"
                    >
                      Result
                      <tr id="tr-fix">
                        <div class="btn-big">
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabResult !== 'format'"
                              :color="
                                contentTabResult === 'format' ? 'primary' : ''
                              "
                              :outlined="contentTabResult === 'format'"
                              rounded
                              @click="contentTabResult = 'format'"
                            >
                              <span>Format</span>
                            </v-btn>
                          </td>
                          <td>
                            <v-btn
                              class="text-capitalize mr-2 btn-data-formation"
                              :text="contentTabResult !== 'raw'"
                              :color="
                                contentTabResult === 'raw' ? 'primary' : ''
                              "
                              :outlined="contentTabResult === 'raw'"
                              rounded
                              @click="contentTabResult = 'raw'"
                            >
                              <span>Raw</span>
                            </v-btn>
                          </td>
                        </div>
                      </tr>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'result' &&
                        contentTabResult === 'format'
                      "
                    >
                      <span
                        v-if="item.emptyResult"
                        class="expansion-panel-header__header"
                      >WITHOUT CONTENT</span
                      >
                      <v-expansion-panels
                      v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Formated data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <xml-viewer
                              ref="viewer"
                              :xml="
                                formattedXml(
                                  item.result,
                                  item.maskedTags,
                                  item.mask,
                                  item.isMask
                                )
                              "
                              :class="$vuetify.theme.dark ? 'dark' : 'light'"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'result' &&
                        contentTabResult === 'raw'
                      "
                    >
                      <span
                        v-if="item.emptyResult"
                        class="expansion-panel-header__header"
                      >WITHOUT CONTENT</span
                      >
                      <v-expansion-panels
                      v-else
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header>
                            <template v-slot:actions>
                              <v-icon class="expansion-panel-header__icon"
                                >$expand</v-icon
                              >
                            </template>
                            <span class="expansion-panel-header__header"
                              >Raw data</span
                            >
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            {{
                              formattedXml(
                                item.result,
                                item.maskedTags,
                                item.mask,
                                item.isMask
                              )
                            }}
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </td>

                    <td
                      v-if="expandItem.value === 'diff'"
                      width="150"
                      class="pl-3"
                    >
                      Diff
                    </td>
                    <td>
                      <div v-if="expandItem.value === 'diff'" class="d-flex">
                        <span class="w-50">Database config</span>
                        <span>Firewall config</span>
                      </div>
                      <code-diff
                        class="synapsa-table-inside"
                        id="synapsa-table-fix"
                        v-if="expandItem.value === 'diff'"
                        :old-string="
                          formattedXml(
                            item.expectedWithConditions,
                            item.maskedTags,
                            item.mask,
                            item.isMask
                          )
                        "
                        :new-string="
                          formattedXml(
                            item.resultWithConditions,
                            item.maskedTags,
                            item.mask,
                            item.isMask
                          )
                        "
                        output-format="side-by-side"
                      />
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import { CodeDiff } from "v-code-diff";
import {
  getFormattedConditions,
  getFormattedXml,
  maskTags,
} from "@/utils/auditor";
import SearchBar from "@/components/SearchBar";
import XmlViewer from "@/plugins/xmlViewer";
import { exportIntegrityData } from "@/api/monitor/auditor";
import { Errors } from "@/utils/error";

export default {
  components: {
    CodeDiff,
    SearchBar,
    XmlViewer,
  },
  props: {
    route: {
      type: String,
      default: function () {
        return "active";
      },
    },
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      contentTabExpected: "format",
      contentTabResult: "format",
      permissions: localStorage.getItem("permissions").split(","),
      selected: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      sortBy: undefined,
      sortDesc: undefined,

      sizes: [5, 10, 25, 50, 100, 200],
      severityColors: ["neutral-3", "good-3", "medium-3", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
      exportLoading: false,
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", {
            page: newValue,
            size: this.sizes[this.size],
            sortDesc: this.sortDesc,
            by: this.sortBy,
          });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", {
            page: this.page,
            size: this.sizes[newValue],
            sortDesc: this.sortDesc,
            by: this.sortBy,
          });
        }
      },
    },
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      this.keywordAddForm = "integrityalert";
    },
    proceed(event) {
      this.$emit("proceed", {
        selected: this.selected,
        comment: event.comment,
      });
    },
    formattedXml(xml, maskedTags, mask, isMask) {
      if (xml && maskedTags && mask && isMask) {
        return getFormattedXml(maskTags(xml, maskedTags, mask));
      }
      return getFormattedXml(xml);
    },
    formattedConditions(text) {
      return getFormattedConditions(text);
    },
    onMaskClick(item, index) {
      this.$emit("onMaskClick", { data: item, index });
    },
    checkPermission(perms) {
      return this.permissions.some((p) => perms.includes(p));
    },
    customSort() {
      // Tato divocin vznikla z dovodu ze vuetify ma na tabulke 3 stavy a ked nastane 3 stav nesortuj podla nicoho tak
      // sa zavolal 2x change a niekedy to hodilo zly sort aj napriek spravnemu response z DB
      if (
        (this.sortBy !== undefined && this.sortDesc !== undefined) ||
        (this.sortBy === undefined && this.sortDesc === undefined)
      ) {
        this.$emit("change", {
          page: this.page,
          size: this.sizes[this.size],
          sortDesc: this.sortDesc,
          by: this.sortBy,
        });
      }
    },
    severityClass(status) {
      if (status === 1 || status === "1") {
        return "signal-icon--low";
      } else if (status === 2 || status === "2") {
        return "signal-icon--medium";
      } else if (status === 3 || status === "3") {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
    onSearch(event) {
      this.$emit("onSearch", event);
    },
    exportData(event) {
      const data = {
        type: "history",
        search: event.searchTerm,
      };
      this.exportLoading = true;
      exportIntegrityData(data)
        .then((response) => {
          this.exportLoading = false;
          if (response.length > 0) {
            const csvContent = this.$convertToCsv(response);
            const formattedDate = this.$formatTime(new Date());

            this.downloadCsv(
              csvContent,
              "alertlog_integrity_history_" + formattedDate + ".csv"
            );
          } else {
            this.$toast.open({
              message: "Download not allowed for Empty response",
              type: "warning",
              position: "top-right",
              duration: 2000,
            });
          }
        })
        .catch((e) => {
          this.exportLoading = false;
          Errors.show(e);
        });
    },
    downloadCsv(csvContent, filename) {
      const csvBlob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(csvBlob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.condition-list {
  background: transparent;
  padding: 0;
  border: none;
  .v-list-item {
    min-height: 20px;
  }
}
.v-application .elevation-1 {
  box-shadow: none !important;
}
::v-deep .d2h-cntx {
  background-color: #fff;
}
::v-deep .d2h-emptyplaceholder {
  background-color: #f1f1f1;
}
::v-deep .d2h-code-line-prefix,
::v-deep .d2h-wrapper .hljs,
::v-deep .d2h-wrapper .hljs-section,
::v-deep .d2h-wrapper .hljs-keyword,
::v-deep .d2h-wrapper .hljs-string,
::v-deep .d2h-wrapper .hljs-literal,
::v-deep .d2h-wrapper .hljs-title,
::v-deep .d2h-wrapper .hljs-number {
  color: #000;
}

#synapsa-table-fix ::v-deep {
  width: 90%;
  tbody {
    tr:not(.v-data-table__expanded, .expand-tr, .v-data-table__empty-wrapper):last-of-type {
      td {
        border-radius: 0px !important;
        height: 18px !important;
      }
    }
    tr {
      border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
      td {
        border-radius: 0px !important;
        height: 18px !important;
        color: #000;
        border-right: thin solid rgba(0, 0, 0, 0.12) !important;
      }
      td:last-child {
        border-right: none;
      }
    }
  }
}
.btn-data-formation {
  height: 24px !important;
  padding: 0 8px !important;
  min-width: 48px !important;
  border: none;
}
.synapsa-table.theme--light ::v-deep {
  #tr-fix {
    background-color: #f7f7f7 !important;
    td {
      height: 20px !important;
    }
  }
  #tr-fix:hover {
    background-color: #f7f7f7 !important;
  }
}
.synapsa-table.theme--dark ::v-deep {
  #tr-fix {
    background-color: #5e5e6099 !important;
    td {
      height: 20px !important;
    }
  }
  #tr-fix:hover {
    background-color: #5e5e6099 !important;
  }
}
.btn-big {
  border: 1px solid #69be28;
  border-radius: 30px;
  margin: 4px 0 0 -6px;
  position: relative;
  overflow: hidden;
  z-index: 10;
  span {
    font-family: UbuntuBold, sans-serif !important;
    font-size: 0.75rem !important;
  }
}
// .btn-big::before{
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 50%;
//   height: 100%;
//   background-color: #b4df94;
// }
// .btn-big::after{
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 50%;
//   width: 50%;
//   height: 100%;
//   background-color: #b4df94;
//   z-index: -10;
// }
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #f7f7f7;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: transparent;
}
.v-expansion-panel::before {
  box-shadow: none;
}
.v-expansion-panel-header__icon {
  margin-bottom: 6px !important;
}
.expansion-panel-header__icon {
  order: 0;
}
.expansion-panel-header__header {
  order: 1;
}
</style>
